import React from 'react';
import Layout from '../templates/layout';
import Contact from '../components/Contact';
import Seo from '../components/utils/Seo';

const ContactPage = () => (
  <Layout>
    <Contact />
  </Layout>
);

export const Head = ({ location }) => (
  <Seo
    location={location}
    title='Kontakt'
    description='Jeśli masz pytania jak działa Zapłatomatu lub chcesz podzielić się swoimi sugestiami, zapraszamy do kontaktu '
  />
);

export default ContactPage;
