import React, {useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import GitInfo from 'react-git-info/macro';
import BasePageContent from './utils/BasePageContent';
import { CONTACT_CALENDLY } from '../constants';
import PeopleIcon from "@mui/icons-material/People";


export default function Contact() {
  const [disabledButton, setDisabledButton] = useState(false);

    return (
      <BasePageContent title="Informacje kontaktowe">
        <Box textAlign="left" p={2}>
          <Typography variant="h5" paragraph>
            Operatorem usługi Zapłatomat jest Fiberpay sp. z o.o.
          </Typography>
          <Box py={2}>
            <Typography variant='h5' paragraph>Kontakt:</Typography>
            <Typography>FiberPay sp. z o.o.</Typography>
            <Typography>ul. Sienna 86/47</Typography>
            <Typography paragraph>00-815 Warszawa</Typography>
            <Typography>Wsparcie klienta jest dostępne w dni robocze w godzinach od 10:00 do 16:00.</Typography>
            <Typography>Pomoc uzyskasz kontaktując się na {' '}
              <a href="mailto:pomoc@zaplatomat.pl" className='link-inline'>pomoc@zaplatomat.pl</a>
              {' '} lub {' '} <a href="tel:+48 22 230 2622" className='link-inline'>+48 22 230 26 22</a>
            </Typography>
            <Button
              disabled={disabledButton}
              href={CONTACT_CALENDLY}
              className='link-inline'
              variant="outlined"
              startIcon={<PeopleIcon />}
              onClick={() => setDisabledButton(true)}
              sx={{mt:1, '&:hover': { textDecoration: 'none'}}}
            >
              Umów spotkanie online
            </Button>
          </Box>
          <Box py={2}>
            <Typography variant='h5' paragraph>Dane firmy:</Typography>
            <Typography>NIP: 7010634566</Typography>
            <Typography>REGON: 36589948900000</Typography>
            <Typography paragraph>KRS: 0000647662 Sąd Rejonowy Warszawa, XII Wydział Gospodarczy</Typography>
            <Typography>Wpis do rejestru małych instytucji płatniczych: MIP28/2019</Typography>
          </Box>
          <Box style={{ opacity: 0.25 }}>
            {GitInfo().commit.shortHash}
          </Box>
        </Box>
      </BasePageContent>
    );
  }
